import React from "react";
import parse from "html-react-parser";

const Columns = ({ content }) => {
  if (!content?.column?.length || content?.column?.length == 0) {
    return null;
  }

  return content.column.map((column, columnIndex) => (
    <div className="lg:w-6/12 w-full lg:px-50 px-0" key={columnIndex.toString()}>
      <div className="includes-excludes-content">
        {
          column?.heading && (
            <div className="title-black border-b-1 border-gold pb-10">
              <h4>{column.heading}</h4>
            </div>
          )
        }
        <div className="content global-list2">
          {
            column?.description && parse(column.description)
          }
        </div>
      </div>
    </div>
  ))
}

export default Columns;