import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import parse from "html-react-parser";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const TimeLine = ({ content }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setLightBoxOpenStatus] = useState(false);

  const handleNextClickStay = (itemIndex) => {
    const swiper = document.querySelector(`.tabs-with-content #swiper-${itemIndex}`)?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };
  const handlePrevClickStay = (itemIndex) => {
    const swiper = document.querySelector(`.tabs-with-content #swiper-${itemIndex}`)?.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  };

  if (!content?.item?.length || content?.item?.length === 1) {
    return null;
  }

  return content.item.map((item, itemIndex) => (
    <>
      <div className="content-grid lg:mb-50 mb-25" key={itemIndex.toString()}>
        <div className="flex space-x-8 smscreen2:flex-col">
          <div className="title-black">
            <h4>{item?.day}</h4>
          </div>
          <div className="divider"></div>
          <div className="content">
            {item?.heading && <h4>{item?.heading}</h4>}
            <div className="content-inner">
              {
                item?.description && parse(item?.description)
              }
              {
                item?.imagesSlider?.images?.length > 0 && (
                  <div className="content-grid-slider relative">
                    <Swiper
                      id={`swiper-${itemIndex}`}
                      spaceBetween={30}
                      loop={true}
                      slidesPerView={3}
                      speed={900}
                      pagination={false}
                      navigation={true}
                      modules={[Navigation, Pagination]}
                      className="mt-30"
                      breakpoints={{
                        100: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {
                        item?.imagesSlider?.images.map((image, imageIndex) => (
                          <SwiperSlide 
                            key={imageIndex}
                            onClick={() => {
                              setPhotoIndex(imageIndex);
                              setLightBoxOpenStatus(true);
                            }}
                            className="cursor-pointer"
                          >
                            <GatsbyImage 
                              image={getImage(image)}
                              alt={image?.altText}
                            />
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                    <div className="swiper-arrow top-50-per translate-y-minus_50 flex justify-between absolute w-full smscreen2:left-0">
                      <a
                        className="button-prev cursor-pointer"
                        onClick={() => handlePrevClickStay(itemIndex)}
                      ></a>
                      <a
                        className="button-next cursor-pointer"
                        onClick={() => handleNextClickStay(itemIndex)}
                      ></a>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={item?.imagesSlider?.images?.[photoIndex].mediaItemUrl}
          nextSrc={item?.imagesSlider?.images?.[(photoIndex + 1) % item?.imagesSlider?.images?.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            item?.imagesSlider?.images[
            (photoIndex + item?.imagesSlider?.images.length - 1) % item?.imagesSlider?.images?.length
            ]
          }
          onCloseRequest={() => setLightBoxOpenStatus(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + item?.imagesSlider?.images.length - 1) % item?.imagesSlider?.images?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % item?.imagesSlider?.images?.length
            )
          }
        />
      )}
    </>
  ))
}

export default TimeLine;