import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import TimeLine from "./JourneyTabsFields/TimeLine";
import Columns from "./JourneyTabsFields/Columns";
import Gallery from "./JourneyTabsFields/Gallery";
import Reviews from "./JourneyTabsFields/Reviews";

const JourneyTabs = ({ module }) => {
  const [tabOption, setTabOption] = useState(module?.tabs?.length > 0 ? module?.tabs[0]?.tabTitle || "" : "");

  const renderTabContentField = (content, contentIndex) => {
    switch (content?.fieldGroupName.replace("journey_Journey_JourneyContent_JourneyTabs_tabs_Content_", "")) {
      case "Columns": {
        return (
          <div className="tabs-container lg:py-120 py-60">
            <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30 mdscreen:px-30 desktop2:w-[75%]">
              <div className="flex flex-wrap lg:mx-minus-50 mx-0 lgscreen:space-y-10">
                <Columns content={content} key={contentIndex.toString()} />
              </div>
            </div>
          </div>
        )
      }
      case "TimeLine": {
        return (
          <div className="tabs-container lg:py-120 py-60">
            <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30 mdscreen:px-30 desktop2:w-[75%]">
              <TimeLine content={content} key={contentIndex.toString()} />
            </div>
          </div>
        )
      }
      case "Gallery": {
        return <Gallery content={content} key={contentIndex.toString()} />
      }
      case "Reviews": {
        return (
          <div className="tabs-container lg:py-120 py-60">
            <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30 mdscreen:px-30 desktop2:w-[75%]">
              <Reviews content={content} key={contentIndex.toString()} />
            </div>
          </div>
        )
      }
      default: {
        return null;
      }
    }
  }

  const renderSelectedTabContent = () => {
    const selectedTab = module?.tabs.filter((tab) => tab?.tabTitle === tabOption)?.[0] || null;
    if (selectedTab) {
      if (selectedTab?.content?.length > 0) {
        return selectedTab.content.map(renderTabContentField);
      }
      return null;
    }
    return null;
  }

  return (
    <section className="tabs-with-content lg:mt-60 mt-30">
      <div className="bg-gray-200 py-30">
        <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30">
          <ul className='tabs w-full fade-ani flex flex-wrap justify-center lgscreen:justify-between mt-0'>
            {
              module?.tabs?.length > 0 && module.tabs.map((tab, tabIndex) => (
                <li key={tabIndex.toString()} onClick={() => { setTabOption(tab?.tabTitle) }} className={`tab-link cursor-pointer text-black-200 uppercase mx-30 lgscreen:mx-15 text-16 font-heading tracking-0.02 ${(tabOption == tab?.tabTitle) ? 'tab-current' : ''}`}>{tab?.tabTitle}</li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="fade-ani"> {renderSelectedTabContent()}</div>
     
    </section>
  )
}

export default JourneyTabs;
export const JourneyTabsFragment = graphql`
  fragment JourneyTabsFragment on WpJourney_Journey_JourneyContent_JourneyTabs {
    fieldGroupName
    tabs {
      tabTitle
      content {
        ... on WpJourney_Journey_JourneyContent_JourneyTabs_tabs_Content_TimeLine {
          fieldGroupName
          item {
            day
            description
            heading
            imagesSlider {
              images {
                altText
                mediaItemUrl
                gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
              }
            }
          }
        }
        ... on WpJourney_Journey_JourneyContent_JourneyTabs_tabs_Content_Columns {
          fieldGroupName
          column {
            description
            heading
          }
        }
        ... on WpJourney_Journey_JourneyContent_JourneyTabs_tabs_Content_Gallery {
          fieldGroupName
          extraClasses
          images {
            altText
            mediaItemUrl
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
        }
        ... on WpJourney_Journey_JourneyContent_JourneyTabs_tabs_Content_Reviews {
          fieldGroupName
          extraClasses
          heading
          reviews {
            author
            text
            location
          }
        }
      }
    }
  }
`;