import React from "react";
import { graphql } from "gatsby";

import SinglePageBanner from "../components/SinglePageBanner";
import SimpleContent from "../components/SinglePage/SimpleContent";
import RelatedAccommodations from "../components/RelatedAccommodations";
import JourneyTabs from "../components/JourneyTabs";
import SmallImageWithContent from "../components/SmallImageWithContent";
import RelatedJourney from "../components/RelatedJourney";

const Journey = (props) => {
  const modules = props.modules.journeyContent;

  const components = {
    SinglePageBanner,
    SimpleContent,
    RelatedAccommodations,
    JourneyTabs,
    SmallImageWithContent,
    RelatedJourney
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpJourney_Journey_JourneyContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                i,
              })
            );
          })
        : null}
    </>
  )
};

export default Journey;

export const JourneyFragment = graphql`
  fragment JourneyFragment on WpJourney {
    journey {
      journeyContent {
        __typename
        ...SinglePageJourneySingleBannerFragment
        ...JourneySingleSimpleContentFragment
        ...JourneySingleSmallImageWithContentFragment
        ...JourneyTabsFragment
        ...RelatedJourneyFragment
      }
    }
  }
`;
