import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const RelatedAccommodations = ({ module }) => {

  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);

  const openModalVideo = (fullVideoUrl) => {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
  };

  const closeModalVideo = () => {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
  };

  const customStylesVideo = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#000000",
      border: "0",
      borderRadius: "0",
      padding: "0",
      width: "100%",
      height: "100%",
    },
  };

  const renderData = () => {
    if (module?.image) {
      return (
        <GatsbyImage
          image={getImage(module?.image)}
          alt={module?.image?.altText}
        />
      )
    }
    else if (module?.fullVideoUrl && module?.fullVideoUrl !== "" && module?.shortVideoUrl && module?.shortVideoUrl !== "") {
      return (
        <div className="video relative">
          <video width="750" height="500" autoPlay muted loop data-src={module?.shortVideoUrl} poster={module?.fallbackImage?.mediaItemUrl}>
            <source src={module?.shortVideoUrl} type="video/mp4" />
          </video >
          {
            (module?.fullVideoUrl && module?.fullVideoUrl !== "") && (
              <button onClick={() => { openModalVideo(); }} className='flex justify-center items-center m-auto'>
                <div className='video-icon'>
                  <img src="/images/gallery-video.png" className='m-auto' alt="Play" />
                </div>
                <span className='text-white font-heading uppercase text-13 ml-10'>Watch Video</span>
              </button>
            )
          }
        </div>
      )
    }
    else if (module?.shortVideoUrl && module?.shortVideoUrl !== "") {
      return (
        <div className="video relative">
          <video width="750" height="500" autoPlay muted loop data-src={module.shortVideoUrl} poster={module?.fallbackImage?.mediaItemUrl}>
            <source src={module.shortVideoUrl} type="video/mp4" />
          </video >
        </div>
      )
    }
    else if (module?.fallbackImage) {
      return (
        <GatsbyImage
          image={getImage(module?.fallbackImage)}
          alt={module?.fallbackImage?.altText}
        />
      )
    }
    else {
      return null;
    }
  }

  return (
    <>
      <section className={`bg-img-content bg-black-300 lg:mt-70 mt-35${module.extraClass ? ` ${module.extraClass}` : ""}`}>
        <div className="flex flex-wrap items-center">
          <div className="lg:w-5/12 w-full">
            <div className="left-content lg:pl-70 pl-30 lg:py-0 py-50 lg:pr-100">
              {
                module?.heading && (
                  <div className="title-white">
                    <h3>{module.heading}</h3>
                  </div>
                )
              }
              <div className="content white">
                {module?.description && parse(module.description)}
              </div>
              {
                module?.link && (
                  <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                    <Link to={module?.link?.url || "#"} className="btn-link white">{module?.link?.title || "Explore"}</Link>
                  </div>
                )
              }
            </div>
          </div>
          <div className="lg:w-7/12 w-full">
            {renderData()}
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpenVideo}
        onRequestClose={closeModalVideo}
        style={customStylesVideo}
      >
        <div className="video-modal-popup h-full">
          <button onClick={closeModalVideo} className="pr-30 pt-30">
            <img
              src="/images/modal-close.png"
              className="m-auto max-w-20"
              alt=""
            />
          </button>
          {module?.fullVideoUrl &&
            <div className="modal-video h-full">
              <ReactPlayer
                className="modal-banner-video"
                loop={true}
                playing={true}
                controls={true}
                muted={true}
                url={module.fullVideoUrl}
              />
            </div>
          }
        </div>
      </Modal>
    </>
  )
}

export default RelatedAccommodations;
export const RelatedAccommodationsFragment = graphql`
  fragment RelatedAccommodationsFragment on WpAccommodation_Accommodations_PageContent_RelatedAccommodations {
    id
    hideSection
    description
    extraClass
    heading
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
    }
    link {
      target
      title
      url
    }
    fallbackImage {
      altText
      mediaItemUrl
    }
    fullVideoUrl
    shortVideoUrl
  }
`;