import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Journey from "../modules/Journey";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const JourneyTemplate = (props) => {
  const post = props.data.post;

  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    gsap.utils.toArray(".fade-ani").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.4, autoAlpha: 1, y: 0 }
          );
        },
      });
    });

    gsap.utils.toArray(".fade-img").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.3, autoAlpha: 1, y: 0 }
          );
        },
      });
    });

  }, []);

  return (
    <Layout>
      <Seo seo={post.seo}></Seo>
      <Journey modules={post.journey} />
    </Layout>
  );
};

export default JourneyTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query JourneyById($id: String!) {
    post: wpJourney(id: { eq: $id }) {
      title
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...JourneyFragment
    }
  }
`;
