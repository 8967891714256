import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";
import parse from "html-react-parser";

const Reviews = ({ content }) => {
  
  return (
      <section className="text-slider bg-white">
        <div className="max-w-[916px] review-title  m-auto px-20">
          {content?.heading && <h6 className="content text-center">{content.heading}</h6>}
          <div className="text-slider-content">
            <Swiper
              spaceBetween={0}
              loop={true}
              speed={1000}
              slidesPerView={1}
               autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
              className=""
            >
              {content.reviews?.length > 0 && content.reviews?.map((testimonial) => (
                <SwiperSlide key={testimonial.id}>
                  <div className="content text-center text-black-200">
                    {testimonial?.text && parse(testimonial.text)}
                    <div className="user-info pt-30">
                      {testimonial?.author &&
                        <span className="text-14">
                          {testimonial?.author}
                        </span>}
                      {testimonial?.location &&
                        <p className="text-10 text-black-200">
                          {testimonial?.location}
                        </p>
                      }
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
  )
}

export default Reviews;