import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery = ({ content }) => { 
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setLightBoxOpenStatus] = useState(false);
   
  return (
    <>
      <section className={`img-slider lg:py-60 py-30${content.extraClasses ? ` ${content.extraClasses}` : ""}`}>
        <Swiper
            spaceBetween={30}
            loop={true}
            slidesPerView={'auto'}
            speed={900}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            >
              {
                content?.images?.length > 0 && content.images.map((image, index) => (
                  <SwiperSlide 
                    key={index.toString()}
                    onClick={() => {
                      setPhotoIndex(index);
                      setLightBoxOpenStatus(true);
                    }}
                    className="cursor-pointer"
                  >
                    <GatsbyImage 
                      image={getImage(image)}
                      alt={image?.altText || "Slider Image"}
                      className="img"
                    />
                  </SwiperSlide>
                ))
              }
        </Swiper>
      </section>

      {isLightBoxOpen && (
        <Lightbox
          mainSrc={content?.images?.[photoIndex].mediaItemUrl}
          nextSrc={content?.images?.[(photoIndex + 1) % content?.images.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            content?.images[
            (photoIndex + content?.images.length - 1) % content?.images.length
            ]
          }
          onCloseRequest={() => setLightBoxOpenStatus(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + content?.images.length - 1) % content?.images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % content?.images.length
            )
          }
        />
      )}
    </>
  )
}

export default Gallery;