import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

const RelatedJourney = ({ module }) => {

  const handleNextClickStay = () => {
    const swiper = document.querySelector(".discover-slider .swiper").swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector(".discover-slider .swiper").swiper;
    swiper.slidePrev();
  };

  return (
    <section className={`discover-slider lg:py-120 py-60${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid pr-0">
        <div className="flex flex-wrap items-center">
          <div className="lg:w-4/12 fade-ani fade-ani w-full">
            <div className="discover-content lg:w-[333px]">
              <div className="title-black">
                <h3>{module?.heading && module.heading}</h3>
              </div>
              <div className="content">
                {module?.description && parse(module.description)}
              </div>
              <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                <Link to={module?.link?.url ? module?.link?.url : "#"} className="btn-link gold">{module?.link.title && module.link.title}</Link>
              </div>
            </div>
          </div>
          <div className="lg:w-8/12 w-full">
            <div className="discover-slider-inner fade-img relative lg:pl-100 pl-80 smscreen2:pl-60 lg:pt-0 pt-30 lg:pr-0 mdscreen:pr-20">
              <Swiper
                spaceBetween={40}
                loop={true}
                slidesPerView={1.5}
                speed={900}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                breakpoints={{
                  100: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1.5,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 1.5,
                    spaceBetween: 40,
                  },
                }}
              >
                {
                  module?.journeyPost?.length > 0 && module.journeyPost.map((post, postIndex) => {
                    const { image = null, facility = "", price = "" } = post.journey?.journeyFields;
                    return (
                      <SwiperSlide key={postIndex.toString()}>
                        <GatsbyImage
                          image={getImage(image)}
                          alt={image?.altText}
                        />
                        <div className="discover-slider-info pt-30 lg:pr-40">
                          <ul className="pb-10 flex flex-wrap">
                            {facility && <li>{facility}</li>}
                            {price && <li>{price}</li>}
                          </ul>
                          <div className="title-black">
                            <h4>{post?.title && post.title}</h4>
                          </div>
                          <div className="content">
                            {post?.content && parse(post?.content)}
                          </div>
                          <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                            <Link to={post?.uri ? post?.uri : "#"} className="btn-link gold">Explore</Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ) 
                  })
                }
              </Swiper>
              <div className="swiper-arrow">
                <a
                  className="button-prev cursor-pointer"
                  onClick={handlePrevClickStay}
                ></a>
                <a
                  className="button-next cursor-pointer"
                  onClick={handleNextClickStay}
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RelatedJourney;
export const RelatedJourneyFragment = graphql`
  fragment RelatedJourneyFragment on WpJourney_Journey_JourneyContent_RelatedJourney {
    id
    hideSection
    description
    extraClass
    heading
    link {
      target
      title
      url
    }
    journeyPost {
      ... on WpJourney {
        id
        title
        uri
        journey {
          fieldGroupName
          journeyFields {
            facility
            price
            image {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
            }
          }
        }
        content
      }
    }
  }
`;